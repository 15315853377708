import { signOut, deleteUser } from "firebase/auth";
import { useFirebaseAuth, useCurrentUser } from "vuefire";
import { signout, signin, deleteUserFromApi } from "@/services/UserService.js";
import { cancelSubscription } from "@/services/PaymentService";

export function useVimiAuth() {
  const userStore = useUserStore();
  const router = useRouter();
  const auth = useFirebaseAuth();
  const { locale } = useI18n();

  function clearNuxtRelatedData() {
    clearNuxtData();
  }

  async function signInVimiUser(user) {
    try {
      if (userStore.user) userStore.twoFaInProgress = false;

      //This is not firebase, our own API call
      const { data } = await signin({
        email: user.email,
        firebaseId: user.uid,
      });

      //2fa is enabled for this user
      if (data.data.twoFa) {
        userStore.twoFaInProgress = true;
        console.log("redirecting to twofaverify...");
        router.push("/twofaverify");
      } else {
        userStore.user = {
          ...data.data,
          ...userStore.user,
          photoURL: user.photoURL,
        };

        //set locale
        if (userStore.user.language) {
          locale.value = userStore.user.language;
        }
        console.log("redirecting to system...");
        return navigateTo("/system");
      }
    } catch (error) {
      //We are trying to signin, but failed then signout Firebase user to avoid confusion
      await signOutVimiUser();
    }
  }

  async function signOutVimiUser() {
    try {
      await signOut(auth); //signout from firebase
      await signout(); //signout from Vimi API
      userStore.$reset();
      clearNuxtRelatedData();
      router.push("/");
    } catch (error) {
      alert(error);
    }
  }

  async function isPremium() {
    const user = useCurrentUser();
    const result = await user.value.getIdTokenResult();

    return result?.claims?.premium;
  }

  function getError(errorCode) {
    switch (errorCode) {
      case "auth/user-not-found":
        return "User not found.";
      case "auth/wrong-password":
        return "Invalid credentials.";
      case "auth/email-already-in-use":
        return "User already exists.";
      case "auth/too-many-requests":
        return "Access to this account temporarily blocked due to too many requests.";
      case "auth/account-exists-with-different-credential":
        return "You have already connected a different authentication provider with this account. Please try with that provider.";
      default:
        return "An error occured during login. Please try again.";
    }
  }

  async function deleteUserFromFirebase() {
    const user = useCurrentUser();
    await deleteUser(user.value);
  }

  async function deleteVimiUser() {
    const user = useCurrentUser();

    try {
      //first cancel the subscription
      await cancelSubscription(userStore.user.id);

      //then delete from our database
      await deleteUserFromApi(userStore.user.id);

      //then delete from firebase
      await deleteUserFromFirebase();
    } catch (error) {
      //
    }
  }

  async function setUser(user, initialData = {}, auth = false) {
    if (!user) return;
    if (auth) {
      console.log("signing in vimi user...");
      await signInVimiUser(user);
      return;
    }

    const userFromLocalStorage = useLocalStorage("userStore:user");

    console.log("setting user...");

    if (userStore.user && userStore.user != "undefined") {
      userStore.user = { ...userStore.user, ...initialData };
      userStore.user.photoURL = user.photoURL;
    } else if (
      userFromLocalStorage.value &&
      userFromLocalStorage.value != "undefined"
    ) {
      userStore.user = {
        ...JSON.parse(userFromLocalStorage.value),
        email: user.email,
        photoURL: user.photoURL,
      };
    } else {
      userStore.user = {
        email: user.email,
        photoURL: user.photoURL,
      };
    }
  }

  return {
    signInVimiUser,
    signOutVimiUser,
    isPremium,
    getError,
    deleteUserFromFirebase,
    deleteVimiUser,
    setUser,
  };
}
